<template>
  <div class="change-laguage" data-target="dropdown">
    <span data-target="dropdown" class="icon icon-laguage"></span>
    
    <button data-target="dropdown" @click="dropDown = !dropDown">{{ activeLang.title }}</button>
    <div v-if="dropDown" class="change-laguage-dropdown" data-target="dropdown">
      <button
        data-target="dropdown"
        v-for="lang in langData"
        :key="lang.value"
        @click="changeLangs(lang.value)"
        :disabled="lang.value == thisActiveLang"
        :class="lang.value == thisActiveLang ? 'active' : ''"
      >{{ lang.title }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChangeLanguage',
  data() {
    return {
      thisActiveLang: localStorage.getItem('language') || 'uz',
      dropDown: false,
      UZurl: '',
      RUurl: '',
      ENurl: '',
      SiteUrl: '',
      Url: '',
      activeLang: {
        title: 'O’zbekcha',
        value: 'uz',
      },
      langData: [
        {
          title: 'O’zbekcha',
          value: 'uz',
        },
        {
          title: 'Русский',
          value: 'ru',
        },
        {
          title: 'English',
          value: 'en',
        },
      ],
    };
  },
  methods: {
    changeLangs(valueLang) {
      localStorage.setItem('language', valueLang);
      window.location.reload();
      
    },
  },
  mounted() {
      this.Uzurl = window.location.href.substring(21, 24)
      this.RUurl = window.location.href.substring(21, 24)
      this.ENurl = window.location.href.substring(21, 24)
      this.SiteUrl = window.location.href.substring(0, 21)
      this.Url = window.location.href.substring(24)
      if (this.SiteUrl === 'https://siat.stat.uz/' && this.Uzurl === 'uz/'){
        localStorage.setItem('language', 'uz');
        window.history.pushState({},'', this.SiteUrl+this.Url)
        window.location.reload();
      } else if (this.SiteUrl === 'https://siat.stat.uz/' && this.Uzurl === 'ru/'){
        localStorage.setItem('language', 'ru');
        window.history.pushState({},'', this.SiteUrl+this.Url)

        window.location.reload();


      } else if (this.SiteUrl === 'https://siat.stat.uz/' && this.Uzurl === 'en/') {
        localStorage.setItem('language', 'en');
        window.history.pushState({},'', this.SiteUrl+this.Url)
        window.location.reload();
        
      } else {
        this.activeLang = this.langData.find((item) => item.value === this.thisActiveLang);
        window.addEventListener('click', (e) => {
        if (!e.target.dataset.target) {
          this.dropDown = false;
        }
        });
      }

 
  },
};
</script>

<style lang="scss" src="./ChangeLanguage.scss"></style>
