<template>
  <header class="header">
    <div class="container">
      <div class="header-wrapper">
        <page-logo class="header-wrapper-logo" theme="dark" />
        <ul class="header-wrapper-links">
          <li>
            <router-link to="/about-us">{{ $t('aboutSystem') }}</router-link>
          </li>
          <li>
            <router-link to="/all-news">{{ $t('adsPage') }}</router-link>
          </li>
          <li>
            <router-link to="/reports-filed/0/meta-data">
              {{ $t('officialStatisticsData') }}
            </router-link>
          </li>
        </ul>
        <div class="header-wrapper-more-actions">
          <additional-actions />
        </div>
        <div>
          <change-language />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import PageLogo from '@/components/PageLogo';
import AdditionalActions from '@/components/AdditionalActions';
import ChangeLanguage from '@/components/ChangeLanguage';

export default {
  name: 'PublicHeader',
  components: {
    PageLogo,
    AdditionalActions,
    ChangeLanguage,
  },
  data() {
    return {
      burgerActive: false,
    };
  },
  methods: {
    burgerToogle() {
      this.burgerActive = !this.burgerActive;
    },
    adminPageRouter() {
      window.location.href = 'https://app.siat.pixyz.uz/';
    },
  },
};
</script>

<style lang="scss" src="./PublicHeader.scss" scoped></style>
