<template>
    <router-link :to="url" class="page-logo">
    <div class="logo-wrapper">
        <img
        v-if="theme == 'dark'"
        src="@/assets/icons/Light_logo.svg"
        loading="lazy"
        alt="Aholini Ro\'yhatga olish 2023"
        />
        <img
        v-else-if="theme == 'light'"
        src="@/assets/icons/Dark_logo.svg"
        loading="lazy"
        alt="Aholini Ro\'yhatga olish 2023"
        />
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'PageLogo',
  props: {
    url: {
      type: String,
      default: '/',
    },
    theme: {
      type: String,
      default: 'light',
    },
  },
};
</script>

<style lang="scss" src="./PageLogo.scss"></style>
