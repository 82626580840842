<template>
  <div class="additional-actions">
    <v-menu offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="additional__activator">
          <div class="additional__activator_icon">
            <img width="22" src="../../assets/icons/eye.svg" alt="" />
          </div>
          <span class="additional__activator_text">
            {{ $t('specialFacilities') }}
          </span>
        </div>
      </template>

      <div class="actions">
        <ul class="theme-list">
          <li @click="themeActivate('')"><button>A</button></li>
          <li @click="themeActivate('theme-a')"><button >A</button></li>
          <li @click="themeActivate('theme-a1')"><button >A</button></li>
        </ul>
        <div class="font-change">
          <div class="font-header">
            <span>{{ $t('fontSize') }}</span>
            <span>{{ fontSize }}%</span>
          </div>
          <v-slider
              v-model="fontSize"
              @input="fontChange"
              track-color="#f6f9ff"
              track-fill-color="#115E8C"
              thumb-size="20"
              hide-details
          >
          </v-slider>
        </div>
        <div class="voicer">
          <div class="voicer-header">
            <span> {{ $t('screenSpeech') }} </span>
            <v-switch
            dense
            color="green"
            v-model="speechActive"
            hide-details
            ></v-switch>
          </div>
          <div class="speech">
            <img width="11" height="11" src="@/assets/icons/volume-high.svg" alt="" />
            <v-slider
              hide-details
              dense
              track-color="#f6f9ff"
              track-fill-color="#115E8C"
              thumb-size="20"
              step="0.1"
              min="0"
              max="1"
              v-model="speechVolume"
            >
            </v-slider>
          </div>
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AdditionalActions',
  data() {
    return {
      mult: 0,
      currentZoom: 1,
      fontSize: null,
      speechActive: false,
      speechVolume: 1,
    };
  },
  methods: {
    fontChange(value) {
      const fontSize = (value / 100) * 16 + 16;
      const html = document.querySelector('html');
      html.style.fontSize = `${fontSize}px`;
      window.localStorage.removeItem('fontSize');
      window.localStorage.setItem('fontSize', value);
    },
    zoom(number) {
      this.currentZoom += number;
      const body = document.querySelector('body');
      localStorage.setItem('zoom', this.currentZoom);
      body.style.zoom = this.currentZoom;
    },

    themeActivate(theme) {
      localStorage.removeItem('theme');
      localStorage.setItem('theme', theme);
      this.changeTheme(theme);
    },
    ...mapActions(['changeTheme']),
  },
  computed: {
    ...mapGetters(['theme']),
  },
  mounted() {
    window.addEventListener('mouseup', () => {
      if (this.speechActive) {
        window.responsiveVoice.speak(window.getSelection().toString(), 'Turkish Female', {
          volume: this.speechVolume,
        });
      }
    });
    if (localStorage.getItem('fontSize')) {
      const fz = localStorage.getItem('fontSize');
      this.fontSize = fz;
      this.fontChange(fz);
    }
  },
};
</script>

<style lang="scss" src="./AdditionalActions.scss"></style>
